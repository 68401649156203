<template>
  <b-card>
    <validation-observer
      ref="userEdit"
      v-slot="{invalid}"
    >
      <!-- form -->
      <b-form
        @submit.prevent="updateUser()"
      >
        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Endereço
          </h4>
        </div>
        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">
          <!-- Field: CEP-->
          <b-col
            md="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="cep"
              vid="cep"
              rules="required|numeric"
            >
              <b-form-group
                label="CEP"
                label-for="cep"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="cep"
                  v-model="user.cep"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: Street -->
          <b-col
            md="10"
          >
            <validation-provider
              v-slot="{ errors }"
              name="street"
              vid="street"
              rules="required|alpha_spaces"
            >
              <b-form-group
                label="Rua"
                label-for="street"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="street"
                  v-model="user.street"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Number -->
          <b-col
            md="2"
          >
            <validation-provider
              v-slot="{ errors }"
              name="number"
              vid="number"
              rules="required|integer"
            >
              <b-form-group
                label="N°"
                label-for="number"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="number"
                  v-model="user.number"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: complement -->
          <b-col
            md="12"
          >
            <validation-provider
              v-slot="{ errors }"
              name="complement"
              vid="complement"
              rules="required|alpha_spaces"
            >
              <b-form-group
                label="Complemento"
                label-for="complement"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="complement"
                  v-model="user.complemento"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: neighborhood -->
          <b-col
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="street"
              vid="street"
              rules="required|alpha_spaces"
            >
              <b-form-group
                label="Bairro"
                label-for="neighborhood"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="neighborhood"
                  v-model="user.neighborhood"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: City -->
          <b-col
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="city"
              vid="city"
              rules="required|alpha_spaces"
            >
              <b-form-group
                label="Cidade"
                label-for="city"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="city"
                  v-model="user.city"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: state -->
          <b-col
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="state"
              vid="state"
              rules="required|alpha_spaces"
            >
              <b-form-group
                label="Estado"
                label-for="state"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="state"
                  v-model="user.state"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: country -->
          <b-col
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="country"
              vid="country"
              rules="required"
            >
              <b-form-group
                label="País"
                label-for="country"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-select
                  id="country"
                  v-model="user.country"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countrys"
                  :state="errors.length > 0 ? false:null"
                  name="country"
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              :disabled="invalid && !alpha"
            >
              Salvar mudanças
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      alphaError: '',
      alpha: '',
      genders: [
        { value: 'Ele/Dele', text: 'Ele/Dele' },
        { value: 'Ela/Dela', text: 'Ela/Dela' },
      ],
      countrys: [
        {
          // gentilico: 'afegãne',
          text: 'Afeganistão',
          // nome_pais_int: 'Afghanistan',
          value: 'AF'
        },
        {
          // gentilico: 'sul-africana',
          text: 'África do Sul',
          // nome_pais_int: 'South Africa',
          value: 'ZA'
        },
        {
          // gentilico: 'albanesa',
          text: 'Albânia',
          // nome_pais_int: 'Albania',
          value: 'AL'
        },
        {
          // gentilico: 'alemã',
          text: 'Alemanha',
          // nome_pais_int: 'Germany',
          value: 'DE'
        },
        {
          // gentilico: 'andorrana',
          text: 'Andorra',
          // nome_pais_int: 'Andorra',
          value: 'AD'
        },
        {
          // gentilico: 'angolana',
          text: 'Angola',
          // nome_pais_int: 'Angola',
          value: 'AO'
        },
        {
          // gentilico: 'anguillana',
          text: 'Anguilla',
          // nome_pais_int: 'Anguilla',
          value: 'AI'
        },
        {
          // gentilico: 'de antártida',
          text: 'Antártida',
          // nome_pais_int: 'Antarctica',
          value: 'AQ'
        },
        {
          // gentilico: 'antiguana',
          text: 'Antígua e Barbuda',
          // nome_pais_int: 'Antigua & Barbuda',
          value: 'AG'
        },
        {
          // gentilico: 'saudita',
          text: 'Arábia Saudita',
          // nome_pais_int: 'Saudi Arabia',
          value: 'SA'
        },
        {
          // gentilico: 'argelina',
          text: 'Argélia',
          // nome_pais_int: 'Algeria',
          value: 'DZ'
        },
        {
          // gentilico: 'argentina',
          text: 'Argentina',
          // nome_pais_int: 'Argentina',
          value: 'AR'
        },
        {
          // gentilico: 'armênia',
          text: 'Armênia',
          // nome_pais_int: 'Armenia',
          value: 'AM'
        },
        {
          // gentilico: 'arubana',
          text: 'Aruba',
          // nome_pais_int: 'Aruba',
          value: 'AW'
        },
        {
          // gentilico: 'australiana',
          text: 'Austrália',
          // nome_pais_int: 'Australia',
          value: 'AU'
        },
        {
          // gentilico: 'austríaca',
          text: 'Áustria',
          // nome_pais_int: 'Austria',
          value: 'AT'
        },
        {
          // gentilico: 'azerbaijano',
          text: 'Azerbaijão',
          // nome_pais_int: 'Azerbaijan',
          value: 'AZ'
        },
        {
          // gentilico: 'bahamense',
          text: 'Bahamas',
          // nome_pais_int: 'Bahamas',
          value: 'BS'
        },
        {
          // gentilico: 'barenita',
          text: 'Bahrein',
          // nome_pais_int: 'Bahrain',
          value: 'BH'
        },
        {
          // gentilico: 'bengali',
          text: 'Bangladesh',
          // nome_pais_int: 'Bangladesh',
          value: 'BD'
        },
        {
          // gentilico: 'barbadiana',
          text: 'Barbados',
          // nome_pais_int: 'Barbados',
          value: 'BB'
        },
        {
          // gentilico: 'bielo-russa',
          text: 'Belarus',
          // nome_pais_int: 'Belarus',
          value: 'BY'
        },
        {
          // gentilico: 'belga',
          text: 'Bélgica',
          // nome_pais_int: 'Belgium',
          value: 'BE'
        },
        {
          // gentilico: 'belizenha',
          text: 'Belize',
          // nome_pais_int: 'Belize',
          value: 'BZ'
        },
        {
          // gentilico: 'beninense',
          text: 'Benin',
          // nome_pais_int: 'Benin',
          value: 'BJ'
        },
        {
          // gentilico: 'bermudiana',
          text: 'Bermudas',
          // nome_pais_int: 'Bermuda',
          value: 'BM'
        },
        {
          // gentilico: 'boliviana',
          text: 'Bolívia',
          // nome_pais_int: 'Bolivia',
          value: 'BO'
        },
        {
          // gentilico: 'bósnia',
          text: 'Bósnia-Herzegóvina',
          // nome_pais_int: 'Bosnia & Herzegovina',
          value: 'BA'
        },
        {
          // gentilico: 'betchuana',
          text: 'Botsuana',
          // nome_pais_int: 'Botswana',
          value: 'BW'
        },
        {
          // gentilico: 'brasileira',
          text: 'Brasil',
          // nome_pais_int: 'Brazil',
          value: 'BR'
        },
        {
          // gentilico: 'bruneiana',
          text: 'Brunei',
          // nome_pais_int: 'Brunei',
          value: 'BN'
        },
        {
          // gentilico: 'búlgara',
          text: 'Bulgária',
          // nome_pais_int: 'Bulgaria',
          value: 'BG'
        },
        {
          // gentilico: 'burquinês',
          text: 'Burkina Fasso',
          // nome_pais_int: 'Burkina Faso',
          value: 'BF'
        },
        {
          // gentilico: 'burundinesa',
          text: 'Burundi',
          // nome_pais_int: 'Burundi',
          value: 'BI'
        },
        {
          // gentilico: 'butanesa',
          text: 'Butão',
          // nome_pais_int: 'Bhutan',
          value: 'BT'
        },
        {
          // gentilico: 'cabo-verdiana',
          text: 'Cabo Verde',
          // nome_pais_int: 'Cape Verde',
          value: 'CV'
        },
        {
          // gentilico: 'camaronesa',
          text: 'Camarões',
          // nome_pais_int: 'Cameroon',
          value: 'CM'
        },
        {
          // gentilico: 'cambojana',
          text: 'Camboja',
          // nome_pais_int: 'Cambodia',
          value: 'KH'
        },
        {
          // gentilico: 'canadense',
          text: 'Canadá',
          // nome_pais_int: 'Canada',
          value: 'CA'
        },
        {
          // gentilico: 'canário',
          text: 'Canárias',
          // nome_pais_int: 'Canary Islands',
          value: 'IC'
        },
        {
          // gentilico: 'cazaque',
          text: 'Cazaquistão',
          // nome_pais_int: 'Kazakhstan',
          value: 'KZ'
        },
        {
          // gentilico: 'de ceuta e melilla',
          text: 'Ceuta e Melilla',
          // nome_pais_int: 'Ceuta & Melilla',
          value: 'EA'
        },
        {
          // gentilico: 'chadiana',
          text: 'Chade',
          // nome_pais_int: 'Chad',
          value: 'TD'
        },
        {
          // gentilico: 'chilena',
          text: 'Chile',
          // nome_pais_int: 'Chile',
          value: 'CL'
        },
        {
          // gentilico: 'chinesa',
          text: 'China',
          // nome_pais_int: 'China',
          value: 'CN'
        },
        {
          // gentilico: 'cipriota',
          text: 'Chipre',
          // nome_pais_int: 'Cyprus',
          value: 'CY'
        },
        {
          // gentilico: 'cingapuriana',
          text: 'Cingapura',
          // nome_pais_int: 'Singapore',
          value: 'SG'
        },
        {
          // gentilico: 'colombiana',
          text: 'Colômbia',
          // nome_pais_int: 'Colombia',
          value: 'CO'
        },
        {
          // gentilico: 'comorense',
          text: 'Comores',
          // nome_pais_int: 'Comoros',
          value: 'KM'
        },
        {
          // gentilico: 'congolesa',
          text: 'Congo',
          // nome_pais_int: 'Congo (Republic)',
          value: 'CG'
        },
        {
          // gentilico: 'norte-coreano',
          text: 'Coréia do Norte',
          // nome_pais_int: 'North Korea',
          value: 'KP'
        },
        {
          // gentilico: 'norte-coreana',
          text: 'Coréia do Sul',
          // nome_pais_int: 'South Korea',
          value: 'KR'
        },
        {
          // gentilico: 'marfinense',
          text: 'Costa do Marfim',
          // nome_pais_int: 'Côte d¿Ivoire',
          value: 'CI'
        },
        {
          // gentilico: 'costarriquenha',
          text: 'Costa Rica',
          // nome_pais_int: 'Costa Rica',
          value: 'CR'
        },
        {
          // gentilico: 'croata',
          text: 'Croácia',
          // nome_pais_int: 'Croatia',
          value: 'HR'
        },
        {
          // gentilico: 'cubana',
          text: 'Cuba',
          // nome_pais_int: 'Cuba',
          value: 'CU'
        },
        {
          // gentilico: 'curaçauense',
          text: 'Curaçao',
          // nome_pais_int: 'Curaçao',
          value: 'CW'
        },
        {
          // gentilico: 'chagositano',
          text: 'Diego Garcia',
          // nome_pais_int: 'Diego Garcia',
          value: 'DG'
        },
        {
          // gentilico: 'dinamarquesa',
          text: 'Dinamarca',
          // nome_pais_int: 'Denmark',
          value: 'DK'
        },
        {
          // gentilico: 'djibutiana',
          text: 'Djibuti',
          // nome_pais_int: 'Djibouti',
          value: 'DJ'
        },
        {
          // gentilico: 'dominiquense',
          text: 'Dominica',
          // nome_pais_int: 'Dominica',
          value: 'DM'
        },
        {
          // gentilico: 'egípcia',
          text: 'Egito',
          // nome_pais_int: 'Egypt',
          value: 'EG'
        },
        {
          // gentilico: 'salvadorenha',
          text: 'El Salvador',
          // nome_pais_int: 'El Salvador',
          value: 'SV'
        },
        {
          // gentilico: 'árabe',
          text: 'Emirados Árabes Unidos',
          // nome_pais_int: 'United Arab Emirates',
          value: 'AE'
        },
        {
          // gentilico: 'equatoriana',
          text: 'Equador',
          // nome_pais_int: 'Ecuador',
          value: 'EC'
        },
        {
          // gentilico: 'eritreia',
          text: 'Eritréia',
          // nome_pais_int: 'Eritrea',
          value: 'ER'
        },
        {
          // gentilico: 'eslovaco',
          text: 'Eslováquia',
          // nome_pais_int: 'Slovakia',
          value: 'SK'
        },
        {
          // gentilico: 'esloveno',
          text: 'Eslovênia',
          // nome_pais_int: 'Slovenia',
          value: 'SI'
        },
        {
          // gentilico: 'espanhola',
          text: 'Espanha',
          // nome_pais_int: 'Spain',
          value: 'ES'
        },
        {
          // gentilico: 'norte-americana',
          text: 'Estados Unidos',
          // nome_pais_int: 'United States',
          value: 'US'
        },
        {
          // gentilico: 'estoniana',
          text: 'Estônia',
          // nome_pais_int: 'Estonia',
          value: 'EE'
        },
        {
          // gentilico: 'etíope',
          text: 'Etiópia',
          // nome_pais_int: 'Ethiopia',
          value: 'ET'
        },
        {
          // gentilico: 'fijiana',
          text: 'Fiji',
          // nome_pais_int: 'Fiji',
          value: 'FJ'
        },
        {
          // gentilico: 'filipina',
          text: 'Filipinas',
          // nome_pais_int: 'Philippines',
          value: 'PH'
        },
        {
          // gentilico: 'finlandesa',
          text: 'Finlândia',
          // nome_pais_int: 'Finland',
          value: 'FI'
        },
        {
          // gentilico: 'francesa',
          text: 'França',
          // nome_pais_int: 'France',
          value: 'FR'
        },
        {
          // gentilico: 'gabonesa',
          text: 'Gabão',
          // nome_pais_int: 'Gabon',
          value: 'GA'
        },
        {
          // gentilico: 'gambiana',
          text: 'Gâmbia',
          // nome_pais_int: 'Gambia',
          value: 'GM'
        },
        {
          // gentilico: 'ganense',
          text: 'Gana',
          // nome_pais_int: 'Ghana',
          value: 'GH'
        },
        {
          // gentilico: 'georgiana',
          text: 'Geórgia',
          // nome_pais_int: 'Georgia',
          value: 'GE'
        },
        {
          // gentilico: 'gibraltariana',
          text: 'Gibraltar',
          // nome_pais_int: 'Gibraltar',
          value: 'GI'
        },
        {
          // gentilico: 'inglesa',
          text: 'Grã-Bretanha (Reino Unido, UK)',
          // nome_pais_int: 'United Kingdom',
          value: 'GB'
        },
        {
          // gentilico: 'granadina',
          text: 'Granada',
          // nome_pais_int: 'Grenada',
          value: 'GD'
        },
        {
          // gentilico: 'grega',
          text: 'Grécia',
          // nome_pais_int: 'Greece',
          value: 'GR'
        },
        {
          // gentilico: 'groenlandesa',
          text: 'Groelândia',
          // nome_pais_int: 'Greenland',
          value: 'GL'
        },
        {
          // gentilico: 'guadalupense',
          text: 'Guadalupe',
          // nome_pais_int: 'Guadeloupe',
          value: 'GP'
        },
        {
          // gentilico: 'guamês',
          text: 'Guam (Território dos Estados Unidos)',
          // nome_pais_int: 'Guam',
          value: 'GU'
        },
        {
          // gentilico: 'guatemalteca',
          text: 'Guatemala',
          // nome_pais_int: 'Guatemala',
          value: 'GT'
        },
        {
          // gentilico: 'guernesiano',
          text: 'Guernsey',
          // nome_pais_int: 'Guernsey',
          value: 'GG'
        },
        {
          // gentilico: 'guianense',
          text: 'Guiana',
          // nome_pais_int: 'Guyana',
          value: 'GY'
        },
        {
          // gentilico: 'franco-guianense',
          text: 'Guiana Francesa',
          // nome_pais_int: 'French Guiana',
          value: 'GF'
        },
        {
          // gentilico: 'guinéu-equatoriano ou equatoguineana',
          text: 'Guiné',
          // nome_pais_int: 'Guinea',
          value: 'GN'
        },
        {
          // gentilico: 'guinéu-equatoriano',
          text: 'Guiné Equatorial',
          // nome_pais_int: 'Equatorial Guinea',
          value: 'GQ'
        },
        {
          // gentilico: 'guineense',
          text: 'Guiné-Bissau',
          // nome_pais_int: 'Guinea-Bissau',
          value: 'GW'
        },
        {
          // gentilico: 'haitiana',
          text: 'Haiti',
          // nome_pais_int: 'Haiti',
          value: 'HT'
        },
        {
          // gentilico: 'holandês',
          text: 'Holanda',
          // nome_pais_int: 'Netherlands',
          value: 'NL'
        },
        {
          // gentilico: 'hondurenha',
          text: 'Honduras',
          // nome_pais_int: 'Honduras',
          value: 'HN'
        },
        {
          // gentilico: 'hong-konguiana ou chinesa',
          text: 'Hong Kong',
          // nome_pais_int: 'Hong Kong',
          value: 'HK'
        },
        {
          // gentilico: 'húngara',
          text: 'Hungria',
          // nome_pais_int: 'Hungary',
          value: 'HU'
        },
        {
          // gentilico: 'iemenita',
          text: 'Iêmen',
          // nome_pais_int: 'Yemen',
          value: 'YE'
        },
        {
          // gentilico: 'da ilha bouvet',
          text: 'Ilha Bouvet',
          // nome_pais_int: 'Bouvet Island',
          value: 'BV'
        },
        {
          // gentilico: 'da ilha de ascensão',
          text: 'Ilha de Ascensão',
          // nome_pais_int: 'Ascension Island',
          value: 'AC'
        },
        {
          // gentilico: 'da ilha de clipperton',
          text: 'Ilha de Clipperton',
          // nome_pais_int: 'Clipperton Island',
          value: 'CP'
        },
        {
          // gentilico: 'manês',
          text: 'Ilha de Man',
          // nome_pais_int: 'Isle of Man',
          value: 'IM'
        },
        {
          // gentilico: 'natalense',
          text: 'Ilha Natal',
          // nome_pais_int: 'Christmas Island',
          value: 'CX'
        },
        {
          // gentilico: 'pitcairnense',
          text: 'Ilha Pitcairn',
          // nome_pais_int: 'Pitcairn Islands',
          value: 'PN'
        },
        {
          // gentilico: 'reunionense',
          text: 'Ilha Reunião',
          // nome_pais_int: 'Réunion',
          value: 'RE'
        },
        {
          // gentilico: 'alandês',
          text: 'Ilhas Aland',
          // nome_pais_int: 'Åland Islands',
          value: 'AX'
        },
        {
          // gentilico: 'caimanês',
          text: 'Ilhas Cayman',
          // nome_pais_int: 'Cayman Islands',
          value: 'KY'
        },
        {
          // gentilico: 'coquense',
          text: 'Ilhas Cocos',
          // nome_pais_int: 'Cocos (Keeling) Islands',
          value: 'CC'
        },
        {
          // gentilico: 'cookense',
          text: 'Ilhas Cook',
          // nome_pais_int: 'Cook Islands',
          value: 'CK'
        },
        {
          // gentilico: 'faroense',
          text: 'Ilhas Faroes',
          // nome_pais_int: 'Faroe Islands',
          value: 'FO'
        },
        {
          // gentilico: 'das ilhas geórgia do sul e sandwich do sul',
          text: 'Ilhas Geórgia do Sul e Sandwich do Sul',
          // nome_pais_int: 'South Georgia & South Sandwich Islands',
          value: 'GS'
        },
        {
          // gentilico: 'das ilhas heard e mcdonald',
          text: 'Ilhas Heard e McDonald (Território da Austrália)',
          // nome_pais_int: 'Heard & McDonald Islands',
          value: 'HM'
        },
        {
          // gentilico: 'malvinense',
          text: 'Ilhas Malvinas',
          // nome_pais_int: 'Falkland Islands (Islas Malvinas)',
          value: 'FK'
        },
        {
          // gentilico: 'norte-marianense',
          text: 'Ilhas Marianas do Norte',
          // nome_pais_int: 'Northern Mariana Islands',
          value: 'MP'
        },
        {
          // gentilico: 'marshallino',
          text: 'Ilhas Marshall',
          // nome_pais_int: 'Marshall Islands',
          value: 'MH'
        },
        {
          // gentilico: 'das ilhas menores afastadas',
          text: 'Ilhas Menores dos Estados Unidos',
          // nome_pais_int: 'U.S. Outlying Islands',
          value: 'UM'
        },
        {
          // gentilico: 'norfolquino',
          text: 'Ilhas Norfolk',
          // nome_pais_int: 'Norfolk Island',
          value: 'NF'
        },
        {
          // gentilico: 'salomônico',
          text: 'Ilhas Salomão',
          // nome_pais_int: 'Solomon Islands',
          value: 'SB'
        },
        {
          // gentilico: 'seichelense',
          text: 'Ilhas Seychelles',
          // nome_pais_int: 'Seychelles',
          value: 'SC'
        },
        {
          // gentilico: 'toquelauano',
          text: 'Ilhas Tokelau',
          // nome_pais_int: 'Tokelau',
          value: 'TK'
        },
        {
          // gentilico: 'turquês',
          text: 'Ilhas Turks e Caicos',
          // nome_pais_int: 'Turks & Caicos Islands',
          value: 'TC'
        },
        {
          // gentilico: 'virginense',
          text: 'Ilhas Virgens (Estados Unidos)',
          // nome_pais_int: 'U.S. Virgin Islands',
          value: 'VI'
        },
        {
          // gentilico: 'virginense',
          text: 'Ilhas Virgens (Inglaterra)',
          // nome_pais_int: 'British Virgin Islands',
          value: 'VG'
        },
        {
          // gentilico: 'indiano',
          text: 'Índia',
          // nome_pais_int: 'India',
          value: 'IN'
        },
        {
          // gentilico: 'indonésia',
          text: 'Indonésia',
          // nome_pais_int: 'Indonesia',
          value: 'ID'
        },
        {
          // gentilico: 'iraniano',
          text: 'Irã',
          // nome_pais_int: 'Iran',
          value: 'IR'
        },
        {
          // gentilico: 'iraquiana',
          text: 'Iraque',
          // nome_pais_int: 'Iraq',
          value: 'IQ'
        },
        {
          // gentilico: 'irlandesa',
          text: 'Irlanda',
          // nome_pais_int: 'Ireland',
          value: 'IE'
        },
        {
          // gentilico: 'islandesa',
          text: 'Islândia',
          // nome_pais_int: 'Iceland',
          value: 'IS'
        },
        {
          // gentilico: 'israelense',
          text: 'Israel',
          // nome_pais_int: 'Israel',
          value: 'IL'
        },
        {
          // gentilico: 'italiana',
          text: 'Itália',
          // nome_pais_int: 'Italy',
          value: 'IT'
        },
        {
          // gentilico: 'jamaicana',
          text: 'Jamaica',
          // nome_pais_int: 'Jamaica',
          value: 'JM'
        },
        {
          // gentilico: 'japonesa',
          text: 'Japão',
          // nome_pais_int: 'Japan',
          value: 'JP'
        },
        {
          // gentilico: 'canalina',
          text: 'Jersey',
          // nome_pais_int: 'Jersey',
          value: 'JE'
        },
        {
          // gentilico: 'jordaniana',
          text: 'Jordânia',
          // nome_pais_int: 'Jordan',
          value: 'JO'
        },
        {
          // gentilico: 'kiribatiana',
          text: 'Kiribati',
          // nome_pais_int: 'Kiribati',
          value: 'KI'
        },
        {
          // gentilico: 'kosovar',
          text: 'Kosovo',
          // nome_pais_int: 'Kosovo',
          value: 'XK'
        },
        {
          // gentilico: 'kuwaitiano',
          text: 'Kuait',
          // nome_pais_int: 'Kuwait',
          value: 'KW'
        },
        {
          // gentilico: 'laosiana',
          text: 'Laos',
          // nome_pais_int: 'Laos',
          value: 'LA'
        },
        {
          // gentilico: 'lesota',
          text: 'Lesoto',
          // nome_pais_int: 'Lesotho',
          value: 'LS'
        },
        {
          // gentilico: 'letão',
          text: 'Letônia',
          // nome_pais_int: 'Latvia',
          value: 'LV'
        },
        {
          // gentilico: 'libanesa',
          text: 'Líbano',
          // nome_pais_int: 'Lebanon',
          value: 'LB'
        },
        {
          // gentilico: 'liberiana',
          text: 'Libéria',
          // nome_pais_int: 'Liberia',
          value: 'LR'
        },
        {
          // gentilico: 'líbia',
          text: 'Líbia',
          // nome_pais_int: 'Libya',
          value: 'LY'
        },
        {
          // gentilico: 'liechtensteiniense',
          text: 'Liechtenstein',
          // nome_pais_int: 'Liechtenstein',
          value: 'LI'
        },
        {
          // gentilico: 'lituana',
          text: 'Lituânia',
          // nome_pais_int: 'Lithuania',
          value: 'LT'
        },
        {
          // gentilico: 'luxemburguesa',
          text: 'Luxemburgo',
          // nome_pais_int: 'Luxembourg',
          value: 'LU'
        },
        {
          // gentilico: 'macauense',
          text: 'Macau',
          // nome_pais_int: 'Macau',
          value: 'MO'
        },
        {
          // gentilico: 'macedônio',
          text: 'Macedônia (República Yugoslava)',
          // nome_pais_int: 'Macedonia (FYROM)',
          value: 'MK'
        },
        {
          // gentilico: 'malgaxe',
          text: 'Madagascar',
          // nome_pais_int: 'Madagascar',
          value: 'MG'
        },
        {
          // gentilico: 'malaia',
          text: 'Malásia',
          // nome_pais_int: 'Malaysia',
          value: 'MY'
        },
        {
          // gentilico: 'malauiano',
          text: 'Malaui',
          // nome_pais_int: 'Malawi',
          value: 'MW'
        },
        {
          // gentilico: 'maldívia',
          text: 'Maldivas',
          // nome_pais_int: 'Maldives',
          value: 'MV'
        },
        {
          // gentilico: 'malinesa',
          text: 'Mali',
          // nome_pais_int: 'Mali',
          value: 'ML'
        },
        {
          // gentilico: 'maltesa',
          text: 'Malta',
          // nome_pais_int: 'Malta',
          value: 'MT'
        },
        {
          // gentilico: 'marroquina',
          text: 'Marrocos',
          // nome_pais_int: 'Morocco',
          value: 'MA'
        },
        {
          // gentilico: 'martiniquense',
          text: 'Martinica',
          // nome_pais_int: 'Martinique',
          value: 'MQ'
        },
        {
          // gentilico: 'mauriciana',
          text: 'Maurício',
          // nome_pais_int: 'Mauritius',
          value: 'MU'
        },
        {
          // gentilico: 'mauritana',
          text: 'Mauritânia',
          // nome_pais_int: 'Mauritania',
          value: 'MR'
        },
        {
          // gentilico: 'maiotense',
          text: 'Mayotte',
          // nome_pais_int: 'Mayotte',
          value: 'YT'
        },
        {
          // gentilico: 'mexicana',
          text: 'México',
          // nome_pais_int: 'Mexico',
          value: 'MX'
        },
        {
          // gentilico: 'micronésia',
          text: 'Micronésia',
          // nome_pais_int: 'Micronesia',
          value: 'FM'
        },
        {
          // gentilico: 'moçambicana',
          text: 'Moçambique',
          // nome_pais_int: 'Mozambique',
          value: 'MZ'
        },
        {
          // gentilico: 'moldavo',
          text: 'Moldova',
          // nome_pais_int: 'Moldova',
          value: 'MD'
        },
        {
          // gentilico: 'monegasca',
          text: 'Mônaco',
          // nome_pais_int: 'Monaco',
          value: 'MC'
        },
        {
          // gentilico: 'mongol',
          text: 'Mongólia',
          // nome_pais_int: 'Mongolia',
          value: 'MN'
        },
        {
          // gentilico: 'montenegra',
          text: 'Montenegro',
          // nome_pais_int: 'Montenegro',
          value: 'ME'
        },
        {
          // gentilico: 'montserratiano',
          text: 'Montserrat',
          // nome_pais_int: 'Montserrat',
          value: 'MS'
        },
        {
          // gentilico: 'birmanês',
          text: 'Myanma',
          // nome_pais_int: 'Myanmar (Burma)',
          value: 'MM'
        },
        {
          // gentilico: 'namíbia',
          text: 'Namíbia',
          // nome_pais_int: 'Namibia',
          value: 'NA'
        },
        {
          // gentilico: 'nauruana',
          text: 'Nauru',
          // nome_pais_int: 'Nauru',
          value: 'NR'
        },
        {
          // gentilico: 'nepalesa',
          text: 'Nepal',
          // nome_pais_int: 'Nepal',
          value: 'NP'
        },
        {
          // gentilico: 'nicaraguense',
          text: 'Nicarágua',
          // nome_pais_int: 'Nicaragua',
          value: 'NI'
        },
        {
          // gentilico: 'nigerina',
          text: 'Níger',
          // nome_pais_int: 'Niger',
          value: 'NE'
        },
        {
          // gentilico: 'nigeriana',
          text: 'Nigéria',
          // nome_pais_int: 'Nigeria',
          value: 'NG'
        },
        {
          // gentilico: 'niueana',
          text: 'Niue',
          // nome_pais_int: 'Niue',
          value: 'NU'
        },
        {
          // gentilico: 'norueguesa',
          text: 'Noruega',
          // nome_pais_int: 'Norway',
          value: 'NO'
        },
        {
          // gentilico: 'caledônia',
          text: 'Nova Caledônia',
          // nome_pais_int: 'New Caledonia',
          value: 'NC'
        },
        {
          // gentilico: 'neozelandesa',
          text: 'Nova Zelândia',
          // nome_pais_int: 'New Zealand',
          value: 'NZ'
        },
        {
          // gentilico: 'omani',
          text: 'Omã',
          // nome_pais_int: 'Oman',
          value: 'OM'
        },
        {
          // gentilico: 'dos países baixos caribenhos',
          text: 'Países Baixos Caribenhos',
          // nome_pais_int: 'Caribbean Netherlands',
          value: 'BQ'
        },
        {
          // gentilico: 'palauense',
          text: 'Palau',
          // nome_pais_int: 'Palau',
          value: 'PW'
        },
        {
          // gentilico: 'palestino',
          text: 'Palestina',
          // nome_pais_int: 'Palestine',
          value: 'PS'
        },
        {
          // gentilico: 'zona do canal do panamá',
          text: 'Panamá',
          // nome_pais_int: 'Panama',
          value: 'PA'
        },
        {
          // gentilico: 'pauásia',
          text: 'Papua-Nova Guiné',
          // nome_pais_int: 'Papua New Guinea',
          value: 'PG'
        },
        {
          // gentilico: 'paquistanesa',
          text: 'Paquistão',
          // nome_pais_int: 'Pakistan',
          value: 'PK'
        },
        {
          // gentilico: 'paraguaia',
          text: 'Paraguai',
          // nome_pais_int: 'Paraguay',
          value: 'PY'
        },
        {
          // gentilico: 'peruana',
          text: 'Peru',
          // nome_pais_int: 'Peru',
          value: 'PE'
        },
        {
          // gentilico: 'franco-polinésia',
          text: 'Polinésia Francesa',
          // nome_pais_int: 'French Polynesia',
          value: 'PF'
        },
        {
          // gentilico: 'polonesa',
          text: 'Polônia',
          // nome_pais_int: 'Poland',
          value: 'PL'
        },
        {
          // gentilico: 'portorriquenha',
          text: 'Porto Rico',
          // nome_pais_int: 'Puerto Rico',
          value: 'PR'
        },
        {
          // gentilico: 'portuguesa',
          text: 'Portugal',
          // nome_pais_int: 'Portugal',
          value: 'PT'
        },
        {
          // gentilico: 'catarense',
          text: 'Qatar',
          // nome_pais_int: 'Qatar',
          value: 'QA'
        },
        {
          // gentilico: 'queniano',
          text: 'Quênia',
          // nome_pais_int: 'Kenya',
          value: 'KE'
        },
        {
          // gentilico: 'quirguiz',
          text: 'Quirguistão',
          // nome_pais_int: 'Kyrgyzstan',
          value: 'KG'
        },
        {
          // gentilico: 'centro-africana',
          text: 'República Centro-Africana',
          // nome_pais_int: 'Central African Republic',
          value: 'CF'
        },
        {
          // gentilico: 'congolesa',
          text: 'República Democrática do Congo',
          // nome_pais_int: 'Congo (DRC)',
          value: 'CD'
        },
        {
          // gentilico: 'dominicana',
          text: 'República Dominicana',
          // nome_pais_int: 'Dominican Republic',
          value: 'DO'
        },
        {
          // gentilico: 'tcheco',
          text: 'República Tcheca',
          // nome_pais_int: 'Czech Republic',
          value: 'CZ'
        },
        {
          // gentilico: 'romena',
          text: 'Romênia',
          // nome_pais_int: 'Romania',
          value: 'RO'
        },
        {
          // gentilico: 'ruandesa',
          text: 'Ruanda',
          // nome_pais_int: 'Rwanda',
          value: 'RW'
        },
        {
          // gentilico: 'russa',
          text: 'Rússia (antiga URSS) - Federação Russa',
          // nome_pais_int: 'Russia',
          value: 'RU'
        },
        {
          // gentilico: 'saariano',
          text: 'Saara Ocidental',
          // nome_pais_int: 'Western Sahara',
          value: 'EH'
        },
        {
          // gentilico: 'pedro-miquelonense',
          text: 'Saint-Pierre e Miquelon',
          // nome_pais_int: 'St. Pierre & Miquelon',
          value: 'PM'
        },
        {
          // gentilico: 'samoana',
          text: 'Samoa Americana',
          // nome_pais_int: 'American Samoa',
          value: 'AS'
        },
        {
          // gentilico: 'samoano',
          text: 'Samoa Ocidental',
          // nome_pais_int: 'Samoa',
          value: 'WS'
        },
        {
          // gentilico: 'samarinês',
          text: 'San Marino',
          // nome_pais_int: 'San Marino',
          value: 'SM'
        },
        {
          // gentilico: 'helenense',
          text: 'Santa Helena',
          // nome_pais_int: 'St. Helena',
          value: 'SH'
        },
        {
          // gentilico: 'santa-lucense',
          text: 'Santa Lúcia',
          // nome_pais_int: 'St. Lucia',
          value: 'LC'
        },
        {
          // gentilico: 'são-bartolomeense',
          text: 'São Bartolomeu',
          // nome_pais_int: 'St. Barthélemy',
          value: 'BL'
        },
        {
          // gentilico: 'são-cristovense',
          text: 'São Cristóvão e Névis',
          // nome_pais_int: 'St. Kitts & Nevis',
          value: 'KN'
        },
        {
          // gentilico: 'são-martinhense',
          text: 'São Martim',
          // nome_pais_int: 'St. Martin',
          value: 'MF'
        },
        {
          // gentilico: 'são-martinhense',
          text: 'São Martinho',
          // nome_pais_int: 'Sint Maarten',
          value: 'SX'
        },
        {
          // gentilico: 'são-tomense',
          text: 'São Tomé e Príncipe',
          // nome_pais_int: 'São Tomé & Príncipe',
          value: 'ST'
        },
        {
          // gentilico: 'sao-vicentino',
          text: 'São Vicente e Granadinas',
          // nome_pais_int: 'St. Vincent & Grenadines',
          value: 'VC'
        },
        {
          // gentilico: 'senegalesa',
          text: 'Senegal',
          // nome_pais_int: 'Senegal',
          value: 'SN'
        },
        {
          // gentilico: 'leonesa',
          text: 'Serra Leoa',
          // nome_pais_int: 'Sierra Leone',
          value: 'SL'
        },
        {
          // gentilico: 'sérvia',
          text: 'Sérvia',
          // nome_pais_int: 'Serbia',
          value: 'RS'
        },
        {
          // gentilico: 'síria',
          text: 'Síria',
          // nome_pais_int: 'Syria',
          value: 'SY'
        },
        {
          // gentilico: 'somali',
          text: 'Somália',
          // nome_pais_int: 'Somalia',
          value: 'SO'
        },
        {
          // gentilico: 'cingalesa',
          text: 'Sri Lanka',
          // nome_pais_int: 'Sri Lanka',
          value: 'LK'
        },
        {
          // gentilico: 'suazi',
          text: 'Suazilândia',
          // nome_pais_int: 'Swaziland',
          value: 'SZ'
        },
        {
          // gentilico: 'sudanesa',
          text: 'Sudão',
          // nome_pais_int: 'Sudan',
          value: 'SD'
        },
        {
          // gentilico: 'sul-sudanês',
          text: 'Sudão do Sul',
          // nome_pais_int: 'South Sudan',
          value: 'SS'
        },
        {
          // gentilico: 'sueca',
          text: 'Suécia',
          // nome_pais_int: 'Sweden',
          value: 'SE'
        },
        {
          // gentilico: 'suíça',
          text: 'Suíça',
          // nome_pais_int: 'Switzerland',
          value: 'CH'
        },
        {
          // gentilico: 'surinamesa',
          text: 'Suriname',
          // nome_pais_int: 'Suriname',
          value: 'SR'
        },
        {
          // gentilico: 'svalbardense',
          text: 'Svalbard',
          // nome_pais_int: 'Svalbard & Jan Mayen',
          value: 'SJ'
        },
        {
          // gentilico: 'tadjique',
          text: 'Tadjiquistão',
          // nome_pais_int: 'Tajikistan',
          value: 'TJ'
        },
        {
          // gentilico: 'tailandesa',
          text: 'Tailândia',
          // nome_pais_int: 'Thailand',
          value: 'TH'
        },
        {
          // gentilico: 'taiwanês',
          text: 'Taiwan',
          // nome_pais_int: 'Taiwan',
          value: 'TW'
        },
        {
          // gentilico: 'tanzaniana',
          text: 'Tanzânia',
          // nome_pais_int: 'Tanzania',
          value: 'TZ'
        },
        {
          // gentilico: 'do território britânico do oceano índico',
          text: 'Território Britânico do Oceano índico',
          // nome_pais_int: 'British Indian Ocean Territory',
          value: 'IO'
        },
        {
          // gentilico: 'do territórios do sul da frança',
          text: 'Territórios do Sul da França',
          // nome_pais_int: 'French Southern Territories',
          value: 'TF'
        },
        {
          // gentilico: 'timorense',
          text: 'Timor-Leste',
          // nome_pais_int: 'Timor-Leste',
          value: 'TL'
        },
        {
          // gentilico: 'togolesa',
          text: 'Togo',
          // nome_pais_int: 'Togo',
          value: 'TG'
        },
        {
          // gentilico: 'tonganesa',
          text: 'Tonga',
          // nome_pais_int: 'Tonga',
          value: 'TO'
        },
        {
          // gentilico: 'trinitário-tobagense',
          text: 'Trinidad e Tobago',
          // nome_pais_int: 'Trinidad & Tobago',
          value: 'TT'
        },
        {
          // gentilico: 'tristanita',
          text: 'Tristão da Cunha',
          // nome_pais_int: 'Tristan da Cunha',
          value: 'TA'
        },
        {
          // gentilico: 'tunisiana',
          text: 'Tunísia',
          // nome_pais_int: 'Tunisia',
          value: 'TN'
        },
        {
          // gentilico: 'turcomana',
          text: 'Turcomenistão',
          // nome_pais_int: 'Turkmenistan',
          value: 'TM'
        },
        {
          // gentilico: 'turca',
          text: 'Turquia',
          // nome_pais_int: 'Turkey',
          value: 'TR'
        },
        {
          // gentilico: 'tuvaluana',
          text: 'Tuvalu',
          // nome_pais_int: 'Tuvalu',
          value: 'TV'
        },
        {
          // gentilico: 'ucraniana',
          text: 'Ucrânia',
          // nome_pais_int: 'Ukraine',
          value: 'UA'
        },
        {
          // gentilico: 'ugandense',
          text: 'Uganda',
          // nome_pais_int: 'Uganda',
          value: 'UG'
        },
        {
          // gentilico: 'uruguaia',
          text: 'Uruguai',
          // nome_pais_int: 'Uruguay',
          value: 'UY'
        },
        {
          // gentilico: 'uzbeque',
          text: 'Uzbequistão',
          // nome_pais_int: 'Uzbekistan',
          value: 'UZ'
        },
        {
          // gentilico: 'vanuatuense',
          text: 'Vanuatu',
          // nome_pais_int: 'Vanuatu',
          value: 'VU'
        },
        {
          // gentilico: 'vaticano',
          text: 'Vaticano',
          // nome_pais_int: 'Vatican City',
          value: 'VA'
        },
        {
          // gentilico: 'venezuelana',
          text: 'Venezuela',
          // nome_pais_int: 'Venezuela',
          value: 'VE'
        },
        {
          // gentilico: 'vietnamita',
          text: 'Vietnã',
          // nome_pais_int: 'Vietnam',
          value: 'VN'
        },
        {
          // gentilico: 'wallis-futunense',
          text: 'Wallis e Futuna',
          // nome_pais_int: 'Wallis & Futuna',
          value: 'WF'
        },
        {
          // gentilico: 'zambiana',
          text: 'Zâmbia',
          // nome_pais_int: 'Zambia',
          value: 'ZM'
        },
        {
          // gentilico: 'zimbabuana',
          text: 'Zimbábue',
          // nome_pais_int: 'Zimbabwe',
          value: 'ZW'
        }
      ],
      user: {
        address: '',
        name: '',
        cpf: '',
        others: '',
        crm: '',
        crmUf: '',
        profession: '',
        photo: '',
        birthDate: '',
        email: '',
        phone: '',
        cep: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        country: '',
        complemento: '',
      },
      required,
      phoneOptions: {
        placeholder: '',
      },
    }
  },
  computed: {
    userState() {
      return this.$store.state.auth.user
    },
    zipCode() {
      return this.user.cep
    },
  },
  watch: {
    user() {
      this.user.cep = this.userState.address.cep
      this.user.street = this.userState.address.street
      this.user.number = this.userState.address.number
      this.user.neighborhood = this.userState.address.neighborhood
      this.user.city = this.userState.address.city
      this.user.state = this.userState.address.state
      this.user.country = this.userState.address.country
      this.user.complemento = this.userState.address.complemento
      this.user.address = this.userState.address
    },
    zipCode() {
      console.log(!this.user.cep)
      if (this.zipCode) {
        if (this.zipCode.length === 8) {
          console.log('entrou aqui')
          this.search_cep()
        }
      }
    },
  },
  async mounted() {
    this.user = this.userState
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
    ...mapActions('profile', ['ActionUpdateUser']),
    ...mapActions('auth', [
      'ActionSetUser',
    ]),
    async updateUser() {
      await this.$refs.userEdit.validate().then(success => {
        if (success) {
          this.ActionUpdateUser(this.user).then(async (api) => {
            switch (api.data.status) {
              case 200:
                await this.ActionSetUser(api.data.response.user)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                // eslint-disable-next-line no-unused-expressions
                window.location.reload
                break
              case 400:
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
                break
              default:
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
            }
          })
        }
      })
    },
    search_cep(event) {
      if (event) {
        event.preventDefault()
      }
      const url_cep = `https://viacep.com.br/ws/${this.zipCode}/json`

      // clear all headers axios to viacep
      axios.defaults.headers.common = null

      axios
        .get(url_cep)
        .then((response) => {
          if (response.data.erro === true) {
            this.user.cep = this.zipCode.replace('-', '')
            return
          }
          this.user.street = response.data.logradouro
          this.user.neighborhood = response.data.bairro
          this.user.state = response.data.uf
          this.user.city = response.data.localidade
          this.user.cep = response.data.cep.replace('-', '')
        })
        .catch((error) => {
          console.log(error.statusText)
        })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
